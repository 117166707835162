import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Col, Row, Spinner } from "reactstrap";
import * as Api from "@/api";
import ConfirmModalTypes, {
  ActionType,
} from "@/components/Common/ConfirmModalTypes";
import PageContainer from "@/components/Common/PageContainer";
import { useLocalizedMessage } from "@/helpers/hooks";
import MenuRow, { Menu } from "@/pages/MenuControl/MenuRow";
import { useMenuStore } from "@/zustandStore/menustore";

const TABLE_HEADERS = [
  { key: "no", label: "No" },
  { key: "name", label: "메뉴명" },
  { key: "icon", label: "아이콘" },
  { key: "url", label: "URL" },
  { key: "order", label: "정렬순번" },
  { key: "use", label: "사용여부" },
  { key: "select", label: "선택" },
  { key: "modifier", label: "수정자" },
  { key: "modDate", label: "수정일자" },
];

const BREADCRUMB_ITEMS = [
  { title: "System Management", link: "#" },
  { title: "Menu Management", link: "#" },
];

const initialNewMenu = {
  pMenuIdx: 0,
  name: "",
  icon: "",
  frontUrl: "",
  backUrl: "",
  useYn: "N" as "N" | "Y",
  dispOrder: 0,
};

const MenuControl = () => {
  const getLocalizedMessage = useLocalizedMessage();
  const { refreshMenuItems } = useMenuStore();

  const [isLoading, setIsLoading] = useState(true);

  const [originalMenuData, setOriginalMenuData] = useState<
    Api.Response.MenuItem[]
  >([]);
  const [mainMenuList, setMainMenuList] = useState<Api.Response.MenuItem[]>([]);
  const [newMenu, setNewMenu] = useState<Api.Request.AddMenu>(initialNewMenu);

  const [selectedParentMenu, setSelectedParentMenu] =
    useState<Api.Response.MenuItem | null>(null);
  const [originalSubMenuData, setOriginalSubMenuData] = useState<
    Api.Response.MenuItem[]
  >([]);
  const [subMenuList, setSubMenuList] = useState<Api.Response.MenuItem[]>([]);
  const [newSubMenu, setNewSubMenu] = useState<Api.Request.AddMenu>({
    ...initialNewMenu,
    pMenuIdx: 0,
  });

  const [selectedMainMenu, setSelectedMainMenu] =
    useState<Api.Request.UpdateMenu | null>(null);
  const [selectedSubMenu, setSelectedSubMenu] =
    useState<Api.Request.UpdateMenu | null>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState<ActionType>("");

  const [selectedMenuIdx, setSelectedMenuIdx] =
    useState<Api.Response.MenuItem | null>(null);

  const openModal = (actionType: ActionType, menu?: Api.Response.MenuItem) => {
    setAction(actionType);

    if (menu) {
      setSelectedMenuIdx(menu);
    }
    setIsOpen(true);
  };

  const getMenuList = async (
    pMenuIdx: number = 0,
    shouldRefreshMain: boolean = false,
  ) => {
    setIsLoading(true);
    try {
      const response = await Api.MenuControl.getList({ pMenuIdx });

      if (pMenuIdx === 0 && shouldRefreshMain) {
        setMainMenuList(response.data);
        setOriginalMenuData([...response.data]);
      } else if (pMenuIdx !== 0) {
        setSubMenuList(response.data);
        setOriginalSubMenuData([...response.data]);
      }
    } catch (error) {
      console.error("Failed fetching Menu List");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMenuList(0, true);
  }, []);

  const handleAddNewMenu = (addMenu: Menu, isSubMenu: boolean) => {
    if (isSubMenu && selectedParentMenu) {
      setNewSubMenu({
        ...addMenu,
        pMenuIdx: selectedParentMenu.menuIdx,
      });
    } else {
      setNewMenu({
        ...addMenu,
        pMenuIdx: 0,
      });
    }
  };

  const handleUpdateMenu = (updatedMenu: Menu) => {
    if ("menuIdx" in updatedMenu) {
      if (
        selectedParentMenu?.menuIdx ===
        (updatedMenu as Api.Response.MenuItem)?.pMenuIdx
      ) {
        setSubMenuList((prev) =>
          prev.map((menu) =>
            menu.menuIdx === updatedMenu.menuIdx
              ? { ...menu, ...updatedMenu }
              : menu,
          ),
        );
        setSelectedSubMenu(updatedMenu);
      } else {
        setMainMenuList((prev) =>
          prev.map((menu) =>
            menu.menuIdx === updatedMenu.menuIdx
              ? { ...menu, ...updatedMenu }
              : menu,
          ),
        );
        setSelectedMainMenu(updatedMenu);
      }
    }
  };

  const handleModalClose = () => {
    setSelectedMainMenu(null);
    setSelectedSubMenu(null);

    setMainMenuList(originalMenuData);
    setSubMenuList(originalSubMenuData);
    setNewMenu(initialNewMenu);
    setNewSubMenu({
      ...initialNewMenu,
      pMenuIdx: selectedParentMenu?.menuIdx || 0,
    });
    setIsOpen(false);

    const allMenuRows = document.querySelectorAll("tr[data-menu-idx]");
    allMenuRows.forEach((row) => {
      const menuRowComponent = row as any;
      if (menuRowComponent.setIsEditing) {
        menuRowComponent.setIsEditing(false);
      }
    });
  };

  const handleRowClick = async (menu: Api.Response.MenuItem) => {
    if (
      !selectedParentMenu ||
      selectedParentMenu.menuIdx !== menu.menuIdx ||
      selectedParentMenu.name !== menu.name
    ) {
      setSelectedParentMenu(menu);

      try {
        const response = await Api.MenuControl.getList({
          pMenuIdx: menu.menuIdx,
        });
        setSubMenuList(response.data);
        setOriginalSubMenuData([...response.data]);
      } catch (error) {
        console.error("Failed fetching Sub Menu List");
      }

      setNewSubMenu({
        ...initialNewMenu,
        pMenuIdx: menu.menuIdx,
      });
    }
  };

  const handleSaveMenu = async () => {
    const selectedMenu =
      selectedMenuIdx?.menuIdx === selectedSubMenu?.menuIdx
        ? selectedSubMenu
        : selectedMainMenu;

    if (
      (action === "save" && newMenu.name && !validateMenuData(newMenu)) ||
      (action === "save" && newSubMenu.name && !validateMenuData(newSubMenu)) ||
      (action === "edit" && selectedMenu && !validateMenuData(selectedMenu))
    ) {
      setIsOpen(false);
      return;
    }

    setIsLoading(true);

    try {
      let response;
      if (action === "save") {
        if (newSubMenu.name) {
          response = await Api.MenuControl.add(newSubMenu);

          if (selectedParentMenu) {
            setNewSubMenu({
              ...initialNewMenu,
              pMenuIdx: selectedParentMenu.menuIdx,
            });
            getMenuList(selectedParentMenu.menuIdx);
            getMenuList(0, true);
          }
        } else if (newMenu.name) {
          response = await Api.MenuControl.add(newMenu);
          setNewMenu(initialNewMenu);
          getMenuList(0, true);
        }
      } else if (action === "edit" && selectedMenu) {
        const requestData: Api.Request.UpdateMenu = {
          menuIdx: selectedMenu.menuIdx,
          name: selectedMenu.name,
          icon: selectedMenu.icon,
          frontUrl: selectedMenu.frontUrl,
          backUrl: selectedMenu.backUrl,
          useYn: selectedMenu.useYn,
          dispOrder: selectedMenu.dispOrder,
        };

        response = await Api.MenuControl.update(requestData);
      }

      await refreshMenuItems();

      const successMessage = getLocalizedMessage(response, "success");
      toast.success(successMessage, {
        autoClose: 3000,
      });
    } catch (error) {
      const errMessage = getLocalizedMessage(error, "error");
      toast.error(errMessage, {
        autoClose: 3000,
      });
      handleModalClose();
    } finally {
      setIsLoading(false);
      setIsOpen(false);
      setSelectedMenuIdx(null);
    }
  };

  const handleDeleteMenu = async () => {
    if (!selectedMenuIdx) return;

    setIsLoading(true);

    try {
      const response = await Api.MenuControl.delete(selectedMenuIdx.menuIdx);
      const successMessage = getLocalizedMessage(response, "success");
      toast.success(successMessage, { autoClose: 3000 });

      if (selectedParentMenu) {
        getMenuList(selectedParentMenu.menuIdx);
      } else {
        getMenuList(0, true);
      }
      getMenuList(0, true);
      await refreshMenuItems();
    } catch (error) {
      const errMessage = getLocalizedMessage(error, "error");
      toast.error(errMessage, { autoClose: 3000 });
    } finally {
      setIsLoading(false);
      setIsOpen(false);
      setSelectedMenuIdx(null);
    }
  };

  const validateMenuData = (menu: Menu): boolean => {
    if (!menu.name.trim() || menu.dispOrder <= 0) {
      toast.error("메뉴명과 정렬순번을 입력해주세요", { autoClose: 3000 });
      return false;
    }
    return true;
  };

  return (
    <PageContainer breadcrumbItems={BREADCRUMB_ITEMS} title="Menu Management">
      <Row>
        <Col className="col-12">
          <div className="card card-body mb-1">
            <h4>상위메뉴목록</h4>
            <div
              className="table-responsive overflow-row"
              style={{
                height: "400px",
                overflowY: "auto",
                boxShadow: "inset 0 -10px 5px -5px rgba(0, 0, 0, 0.1) ",
                scrollBehavior: "smooth",
                position: "relative", // Add this
              }}
            >
              <table
                className="table table-hover mb-0 text-center align-middle"
                style={{
                  minWidth: "33.125rem",
                }}
              >
                <thead
                  className="table-light"
                  style={{ position: "sticky", top: 0, zIndex: 1 }}
                >
                  <tr>
                    {TABLE_HEADERS.map((header) => (
                      <th key={header.key}>{header.label}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <MenuRow
                    menu={newMenu}
                    onChange={(updatedMenu) =>
                      handleAddNewMenu(updatedMenu, false)
                    }
                    openModal={() => openModal("save")}
                    isSubMenu
                    onValidate={validateMenuData}
                  />
                  {isLoading ? (
                    <tr
                      style={{
                        position: "absolute",
                        top: "90%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                      }}
                    >
                      <td>
                        <Spinner className="me-2" color="secondary" />
                      </td>
                    </tr>
                  ) : (
                    mainMenuList.map((menu) => (
                      <MenuRow
                        key={menu.menuIdx}
                        menu={menu}
                        onChange={handleUpdateMenu}
                        isExistingMenu
                        openModal={(action) => openModal(action, menu)}
                        onRowClick={() => handleRowClick(menu)}
                        isSelected={
                          selectedParentMenu?.menuIdx === menu.menuIdx
                        }
                        onValidate={validateMenuData}
                      />
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Col>
        <ConfirmModalTypes
          isLoading={isLoading}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          action={action}
          handleSave={handleSaveMenu}
          handleDelete={handleDeleteMenu}
          onCancel={handleModalClose}
        />
      </Row>
      <Row>
        <Col className="col-12">
          <div className="card card-body">
            <h4>하위메뉴목록: {selectedParentMenu?.name}</h4>
            <div
              className="table-responsive overflow-row"
              style={{
                height: "400px",
                overflowY: "auto",
                boxShadow: "inset 0 -10px 5px -5px rgba(0, 0, 0, 0.1) ",
              }}
            >
              <table
                className="table table-hover mb-0 text-center align-middle"
                style={{
                  minWidth: "33.125rem",
                }}
              >
                <thead
                  className="table-light"
                  style={{ position: "sticky", top: 0, zIndex: 1 }}
                >
                  <tr>
                    {TABLE_HEADERS.map((header) => (
                      <th key={header.key}>{header.label}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {selectedParentMenu && (
                    <>
                      <MenuRow
                        menu={newSubMenu}
                        onChange={(updatedMenu) =>
                          handleAddNewMenu(updatedMenu, true)
                        }
                        openModal={() => openModal("save")}
                        onValidate={validateMenuData}
                      />
                      {isLoading ? (
                        <tr
                          style={{
                            position: "absolute",
                            top: "90%",
                            left: "50%",
                            transform: "translate(-50%,-50%)",
                          }}
                        >
                          <td>
                            <Spinner className="me-2" color="secondary" />
                          </td>
                        </tr>
                      ) : (
                        subMenuList.map((menu) => (
                          <MenuRow
                            key={menu.menuIdx}
                            menu={menu}
                            onChange={handleUpdateMenu}
                            isExistingMenu
                            openModal={(action) => openModal(action, menu)}
                            onValidate={validateMenuData}
                          />
                        ))
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </PageContainer>
  );
};

export default MenuControl;
