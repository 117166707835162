import { useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { CellProps } from "react-table";
import * as Api from "@/api";
import CommonControlPanel from "@/components/Common/CommonControlPanel";
import CustomTableContainerTypes, {
  CustomColumn,
} from "@/components/Common/CustomTableContainerTypes";
import PageContainer from "@/components/Common/PageContainer";
import useQueryParams, { useStateRef } from "@/helpers/hooks";
import { ClickableCell, ImageCellTypes } from "@/pages/Utility/CustomCellsType";

const BREADCRUMB_ITEMS = [
  { title: "Material Manufacturer", link: "#" },
  { title: "List", link: "#" },
];

const MaterialManufacturer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pageSize, page, keyword, dateRange } = useQueryParams();

  const [isLoading, setIsLoading] = useState(false);
  const [customPageSize, setCustomPageSize] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(page);
  const [totalPage, setTotalPage] = useState(0);
  const [totalManufacturers, setTotalManufacturers] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState(keyword);
  const searchKeywordRef = useStateRef(searchKeyword);
  const [selectedDates, setSelectedDates] = useState(dateRange);
  const [clearTrigger, setClearTrigger] = useState(false);

  const [manufacturers, setManufacturers] = useState<
    Api.Response.ManufacturerItem[]
  >([]);

  const handleRowClick = useCallback(
    (item: { mmIdx: number }) => {
      navigate(`/material-manufacturer/${item.mmIdx}${window.location.search}`);
    },
    [navigate],
  );

  const handleAddManufacturer = () => {
    navigate(`/material-manufacturer/new${window.location.search}`);
  };

  useEffect(() => {
    const params = {
      page: currentPage,
      pageSize: customPageSize,
      keyword: searchKeyword,
      startDt: selectedDates[0],
      endDt: selectedDates[1],
    };
    navigate(`${location.pathname}?${queryString.stringify(params)}`);
  }, [
    currentPage,
    customPageSize,
    selectedDates,
    searchKeyword,
    location.pathname,
    navigate,
  ]);

  const fetchMaterialManufacturers = useCallback(async () => {
    setIsLoading(true);

    try {
      const searchKeyword = searchKeywordRef.current;
      const requestData: Api.Request.GetManufacturerList = {
        pageSize: customPageSize,
        nowPage: currentPage + 1,
        pageGroup: 10,
        keyword: typeof searchKeyword === "string" ? searchKeyword : "",
        startDt: selectedDates[0] ? format(selectedDates[0], "yyyy-MM-dd") : "",
        endDt: selectedDates[1] ? format(selectedDates[1], "yyyy-MM-dd") : "",
      };

      const response = await Api.MaterialManufacturer.getList(requestData);
      setTotalPage(response.paging.totalPage);
      setTotalManufacturers(response.paging.totalRecord);
      setManufacturers(response.data);
    } catch (error) {
      console.error("Error fetching Material Manufacturers", error);
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, customPageSize, selectedDates[1], clearTrigger]);

  useEffect(() => {
    fetchMaterialManufacturers();
  }, [fetchMaterialManufacturers]);

  const handleSearch = () => {
    setCurrentPage(0);
    fetchMaterialManufacturers();
  };

  const clearFilters = async () => {
    setSearchKeyword("");
    setSelectedDates(["", ""]);
    setCurrentPage(0);
    setClearTrigger((prev) => !prev);
  };

  const columns: CustomColumn<Api.Response.ManufacturerItem>[] = useMemo(
    () => [
      {
        Header: "No",
        accessor: "mmIdx",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "이미지",
        accessor: "imgPath",
        disableFilters: true,
        filterable: false,
        Cell: ({
          cell: { value },
        }: CellProps<Api.Response.ManufacturerItem>) => (
          <ImageCellTypes value={value} type="material" />
        ),
      },
      {
        Header: "제조사명",
        accessor: "name",
        disableFilters: true,
        filterable: false,
        Cell: ({
          cell: { value },
          row,
        }: CellProps<Api.Response.ManufacturerItem>) => (
          <ClickableCell
            value={value as string}
            row={row.original}
            handleRowClick={handleRowClick}
          />
        ),
      },
      {
        Header: "등록자",
        accessor: "regName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "등록일자",
        accessor: "regDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정자",
        accessor: "updName",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.ManufacturerItem>) =>
          value.length > 0 ? value : "-",
      },
      {
        Header: "수정자일자",
        accessor: "updDt",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.ManufacturerItem>) =>
          value.length > 0 ? value : "-",
      },
    ],
    [],
  );

  return (
    <PageContainer
      breadcrumbItems={BREADCRUMB_ITEMS}
      title="Material Manufacturer"
    >
      <CommonControlPanel
        placeholder="제조사명, 등록자, 수정자명으로 검색해주세요."
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
        clearFilters={clearFilters}
        onSearch={handleSearch}
        dateRange={selectedDates}
        setDateRange={setSelectedDates}
      />
      <CustomTableContainerTypes
        btnTitle="타입 등록"
        sortByIdx="mmIdx"
        handleAddItem={handleAddManufacturer}
        columns={columns || []}
        data={manufacturers || []}
        customPageSize={customPageSize}
        totalPage={totalPage}
        totalRecord={totalManufacturers}
        setCustomPageSize={setCustomPageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isLoading={isLoading}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
      />
    </PageContainer>
  );
};

export default MaterialManufacturer;
