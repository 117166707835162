import { useEffect, useMemo, useState } from "react";
import { CellProps } from "react-table";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, InputGroup, Col, Row } from "reactstrap";
import * as Api from "@/api";
import ConfirmModalTypes, {
  ActionType,
} from "@/components/Common/ConfirmModalTypes";
import CustomTableContainerTypes, {
  CustomColumn,
} from "@/components/Common/CustomTableContainerTypes";
import PageContainer from "@/components/Common/PageContainer";
import ToggleSwitch from "@/components/Common/ToggleSwitch";
import { useLocalizedMessage } from "@/helpers/hooks";

const BREADCRUMB_ITEMS = [
  { title: "System Management", link: "#" },
  { title: "Permission Mapping", link: "#" },
];

const PermissionMapping = () => {
  const getLocalizedMessage = useLocalizedMessage();

  const [isLoading, setIsLoading] = useState(false);
  const [permissionTypes, setPermissionTypes] = useState<
    Api.Response.RoleItem[]
  >([]);
  const [selectedPermissionType, setSelectedPermissionType] = useState<
    number | null
  >(null);
  const [menuList, setMenuList] = useState<Api.Response.PermissionMenuItem[]>(
    [],
  );
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState<ActionType>("");

  const openModal = (actionType: ActionType) => {
    setAction(actionType);
    setIsOpen(true);
  };

  const getPermissionTypes = async () => {
    setIsLoading(true);

    try {
      const response = await Api.PermissionMapping.getList();
      setPermissionTypes(response.data);
    } catch (error) {
      console.error("Failed to fetch permission types:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPermissionTypes();
  }, []);

  useEffect(() => {
    const getMenuList = async () => {
      if (selectedPermissionType === null) return;

      setIsLoading(true);
      try {
        const response = await Api.PermissionMapping.getMenuList(
          selectedPermissionType,
        );
        setMenuList(response.data);
      } catch (error) {
        console.error("Failed to fetch menu list:", error);
      } finally {
        setIsLoading(false);
      }
    };

    getMenuList();
  }, [selectedPermissionType]);

  const handleTypeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = parseInt(e.target.value, 10);
    setSelectedPermissionType(selectedValue);
  };

  const handleCheckboxChange = (menuIdx: number, checked: boolean) => {
    setMenuList((prevList) =>
      prevList.map((item) =>
        item.menuIdx === menuIdx
          ? { ...item, useYn: checked ? "Y" : "N" }
          : item,
      ),
    );
  };

  const handleSave = async () => {
    setIsLoading(true);

    try {
      const transformedList = menuList.map((menu) => ({
        menuIdx: menu.menuIdx,
        roleIdx: Number(selectedPermissionType),
        useYn: menu.useYn,
      }));

      const requestData = {
        list: transformedList,
      };

      const response = await Api.PermissionMapping.update(requestData);
      const successMessage = getLocalizedMessage(response, "success");
      toast.success(successMessage, {
        autoClose: 3000,
      });
    } catch (error) {
      const errMessage = getLocalizedMessage(error, "error");
      toast.error(errMessage, { autoClose: 3000 });
    } finally {
      setIsLoading(false);
      setIsOpen(false);
    }
  };

  const columns: CustomColumn<Api.Response.PermissionMenuItem>[] = useMemo(
    () => [
      {
        Header: "Key",
        accessor: "menuIdx",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "P-Key",
        accessor: "pMenuIdx",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "메뉴명",
        accessor: "name",
        disableFilters: true,
        filterable: false,
        headerStyle: { textAlign: "left" },
        Cell: ({ row }: CellProps<Api.Response.PermissionMenuItem>) => {
          const menu = row.original;
          const indent =
            menu.menuLevel > 1 ? `${(menu.menuLevel - 1) * 20}px` : "0px";

          return (
            <span
              style={{
                paddingLeft: indent,
                display: "flex",
              }}
            >
              {menu.name}
            </span>
          );
        },
      },
      {
        Header: "사용",
        accessor: "useYn",
        disableFilters: true,
        filterable: false,
        Cell: ({ row }: CellProps<Api.Response.PermissionMenuItem>) => {
          const menu = row.original;
          return (
            <ToggleSwitch
              label=""
              id={`displayFlag-${menu.menuIdx || "new"}`}
              checked={menu.useYn === "Y"}
              onChange={(e) =>
                handleCheckboxChange(menu.menuIdx, e.target.checked)
              }
              extraClass="mt-2"
            />
          );
        },
      },
      {
        Header: "등록자",
        accessor: "regName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "등록일자",
        accessor: "regDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정자",
        accessor: "updName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정일자",
        accessor: "updDt",
        disableFilters: true,
        filterable: false,
      },
    ],
    [],
  );

  return (
    <PageContainer
      breadcrumbItems={BREADCRUMB_ITEMS}
      title="Permission Mapping"
    >
      <Card>
        <CardBody>
          <Row>
            <Col sm={12} className="form-control-sm">
              <InputGroup>
                <select
                  className="form-select search-select "
                  id="authMappingSelect"
                  value={selectedPermissionType || ""}
                  onChange={handleTypeSelect}
                >
                  <option value="" disabled>
                    권한 선택
                  </option>
                  {permissionTypes?.map((option, idx) => (
                    <option key={idx} value={option.roleIdx || ""}>
                      {option.name}
                    </option>
                  ))}
                </select>
                <button
                  type="button"
                  className="custom-color btn waves-effect waves-light"
                  onClick={() => openModal("save")}
                >
                  저장
                </button>
              </InputGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <CustomTableContainerTypes
        sortByIdx="menuIdx"
        columns={columns || []}
        data={menuList || []}
        isLoading={isLoading}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
      />
      <ConfirmModalTypes
        isLoading={isLoading}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        action={action}
        handleSave={handleSave}
      />
      <ToastContainer />
    </PageContainer>
  );
};

export default PermissionMapping;
