import { useCallback, useEffect, useState } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Label } from "reactstrap";
import * as Api from "@/api";
import ConfirmModalTypes, {
  ActionType,
} from "@/components/Common/ConfirmModalTypes";
import CustomFileInput from "@/components/Common/CustomFileInput";
import CustomLabel from "@/components/Common/CustomLabel";
import FormButtonsTypes from "@/components/Common/FormButtonsTypes";
import PageContainer from "@/components/Common/PageContainer";
import FormInputTypes from "@/components/Profile/FormInputTypes";
import { useLocalizedMessage } from "@/helpers/hooks";
import { CardImgContainer } from "@/pages/Utility/styled";

type ManufacturerProps = Api.Request.AddManufacturer &
  Partial<Pick<Api.Response.Manufacturer, "mmIdx" | "imgPath">>;

const DEFAULT_MANUF: ManufacturerProps = {
  name: "",
  orderNum: null,
  file: null,
};

type ManufFormProps = {
  isDetailView: boolean;
};
const MaterialManufacturerForm = ({ isDetailView }: ManufFormProps) => {
  const breadcrumbItems = [
    { title: "Material Manufacturer", link: "#" },
    { title: `${isDetailView ? "Edit" : "Add"}`, link: "#" },
  ];

  const { mmIdx } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const getLocalizedMessage = useLocalizedMessage();

  const [isLoading, setIsLoading] = useState(false);
  const [manufacturer, setManufacturer] =
    useState<ManufacturerProps>(DEFAULT_MANUF);
  const [fileName, setFileName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState<ActionType>("");

  const openModal = (actionType: ActionType) => {
    setAction(actionType);
    setIsOpen(true);
  };

  const handleChange = (e: {
    target: {
      name: string;
      value: string;
      files: FileList | null;
    };
  }) => {
    const { name, value, files } = e.target;
    const newValue = files ? files[0] : value;
    if (name === "file" && newValue instanceof File) {
      setFileName((newValue && newValue?.name) || "");
    }
    setManufacturer((prevAcc) => ({ ...prevAcc, [name]: newValue }));
  };

  const getManufacturerByIdx = useCallback(async () => {
    try {
      if (!mmIdx) return;
      const response = await Api.MaterialManufacturer.get(parseInt(mmIdx));
      const existingManufacturer = response.data;
      if (existingManufacturer) {
        setManufacturer({ ...existingManufacturer, file: null });
      }
    } catch (error) {
      console.error("Error fetching Manufacturer from API", error);
    }
  }, [mmIdx]);

  useEffect(() => {
    if (isDetailView) {
      getManufacturerByIdx();
    }
  }, [getManufacturerByIdx, isDetailView]);

  const handleSaveManufacturer = async () => {
    if (
      !manufacturer.name ||
      !manufacturer.orderNum ||
      (!isDetailView && !manufacturer.file)
    ) {
      toast.error("(*) 필드 모두 입력해주세요", {
        autoClose: 3000,
      });
      return;
    }
    setIsLoading(true);

    try {
      const requestData = {
        name: manufacturer.name,
        orderNum: manufacturer.orderNum,
        file: manufacturer.file,
      };

      let response;

      if (isDetailView) {
        if (typeof manufacturer?.mmIdx !== "number") return;
        response = await Api.MaterialManufacturer.update({
          ...requestData,
          mmIdx: manufacturer.mmIdx,
        });
      } else {
        response = await Api.MaterialManufacturer.add(requestData);
      }
      const successMessage = getLocalizedMessage(response, "success");
      toast.success(successMessage, {
        autoClose: 3000,
      });
      setTimeout(() => {
        navigate("/material-manufacturer");
      }, 1000);
    } catch (error) {
      const errMessage = getLocalizedMessage(error, "error");
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setIsLoading(false);
      setIsOpen(false);
    }
  };

  const handleDeleteManufacturer = async () => {
    if (!manufacturer.mmIdx) return;
    setIsLoading(true);

    try {
      const response = await Api.MaterialManufacturer.delete(
        manufacturer?.mmIdx,
      );
      const successMessage = getLocalizedMessage(response, "success");
      toast.success(successMessage, {
        autoClose: 3000,
      });
      setTimeout(() => {
        navigate("/material-manufacturer");
      }, 1000);
    } catch (error) {
      const errMessage = getLocalizedMessage(error, "error");
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(`/material-manufacturer/${location.search}`);
  };

  return (
    <PageContainer
      breadcrumbItems={breadcrumbItems}
      title="Material Management"
    >
      <Card>
        <CardBody>
          <h4 className="card-title">소재 제조사 정보를 입력해주세요.</h4>
          <AvForm className="mt-4">
            <Row>
              <FormInputTypes
                id="name"
                label="제조사명"
                placeholder="제조사명을 입력해주세요"
                type="text"
                value={manufacturer.name || ""}
                onChange={handleChange}
                initialErrorMessage="제조사명을 입력해주세요"
                validate={{
                  required: {
                    value: true,
                  },
                }}
                isRequired
              />
            </Row>

            <Row className="mb-3">
              <CustomLabel htmlFor="customFileInput" title="프로필 이미지" />
              <CustomFileInput
                name="file"
                fileName={fileName}
                onChange={handleChange}
              />
            </Row>
            {isDetailView && !manufacturer.file && manufacturer?.imgPath && (
              <Row className="mb-2">
                <Col>
                  <Label
                    htmlFor="example-tel-input"
                    className="col-md-2 col-form-label"
                  />
                  <CardImgContainer
                    style={{ marginLeft: "4px" }}
                    src={manufacturer.imgPath}
                    alt="Profile"
                    className="rounded avatar-lg"
                  />
                </Col>
              </Row>
            )}
            <Row>
              <FormInputTypes
                id="orderNum"
                label="표시순번"
                placeholder="표시순번을 입력해주세요"
                type="number"
                value={manufacturer.orderNum || ""}
                onChange={handleChange}
                initialErrorMessage="표시순번을 입력해주세요"
                validate={{
                  required: {
                    value: true,
                  },
                }}
                isRequired
              />
            </Row>
            <FormButtonsTypes
              isDetailView={isDetailView}
              openModal={openModal}
              handleCancel={handleCancel}
            />
            <ConfirmModalTypes
              isLoading={isLoading}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              action={action}
              handleSave={handleSaveManufacturer}
              handleDelete={handleDeleteManufacturer}
            />
          </AvForm>
        </CardBody>
      </Card>
      <ToastContainer />
    </PageContainer>
  );
};

export default MaterialManufacturerForm;
