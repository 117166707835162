import React, { Fragment, ReactNode } from "react";
import classNames from "classnames";
import {
  CellProps,
  Column,
  Row as ReactTableRow,
  SortingRule,
  TableState,
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { DefaultColumnFilter } from "./filters";
import { CheckboxCell } from "../../pages/AppEquipment";

const PAGE_SIZE_OPTIONS = [
  { label: "10개씩 보기", value: 10 },
  { label: "20개씩 보기", value: 20 },
  { label: "30개씩 보기", value: 30 },
  { label: "40개씩 보기", value: 40 },
  { label: "50개씩 보기", value: 50 },
  { label: "100개씩 보기", value: 100 },
  { label: "200개씩 보기", value: 200 },
  { label: "300개씩 보기", value: 300 },
  { label: "500개씩 보기", value: 500 },
];

const PAGE_NUMBER_LIMIT = 10;

export type CustomColumn<T extends Record<string, any>> = Column<T> & {
  disableFilters?: boolean;
  filterable?: boolean;
  Cell?: (props: CellProps<T>) => JSX.Element;
};

type CustomTableContainerTypesProps<T extends Record<string, any>> = {
  columns: CustomColumn<T>[];
  data: T[];
  btnTitle?: string;
  btnTitle1?: string;
  handleAddItem?: () => void;
  totalRecord?: number;
  customPageSizeOptions?: boolean;
  isLoading: boolean;
  className?: string;
  totalPage?: number;
  currentPage?: number;
  setCurrentPage?: (page: number) => void;
  setCustomPageSize?: (pageSize: number) => void;
  sortByIdx: string;
  customPageSize?: number;
  tableClassName?: string;
  theadClassName?: string;
  selectedIds?: number[];
  handleCheckboxChange?: (value: number) => void;
  handleCheckAll?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBtn1?: () => void;
  showCheckboxColumn?: boolean;
  btnIcon?: ReactNode;
  filterCondition?: (item: T) => boolean;
  children?: React.ReactNode;
  isFullText?: boolean;
};

const CustomTableContainerTypes = <T extends Record<string, any>>({
  columns,
  data,
  btnTitle,
  btnTitle1,
  handleAddItem,
  totalRecord,
  customPageSizeOptions,
  isLoading,
  className,
  totalPage,
  currentPage,
  setCurrentPage,
  setCustomPageSize,
  sortByIdx,
  customPageSize,
  selectedIds,
  handleCheckboxChange,
  handleCheckAll,
  handleBtn1,
  showCheckboxColumn,
  btnIcon,
  filterCondition,
  children,
  isFullText,
}: CustomTableContainerTypesProps<T>) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    // pageCount,
    gotoPage,
    // nextPage,
    // previousPage,
    setPageSize,
    state,
    // preGlobalFilteredRows,
    // setGlobalFilter,
  } = useTable(
    {
      columns: columns,
      data: data,
      defaultColumn: { Filter: DefaultColumnFilter } as Partial<Column<T>>,
      initialState: {
        pageIndex: currentPage,
        pageSize: customPageSize,
        sortBy: [{ id: sortByIdx, desc: true }] as Array<SortingRule<T>>,
      } as Partial<TableState<T>>,

      manualPagination: true,
      pageCount: totalPage,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        if (!showCheckboxColumn) {
          return columns; // If showCheckboxColumn is false don't show it
        }
        return [
          {
            id: "selection",
            Header: () => (
              <div>
                <Input
                  type="checkbox"
                  className="form-check-input input-mini"
                  checked={
                    (selectedIds?.length || 0) > 0 &&
                    (selectedIds?.length || 0) ===
                      (filterCondition
                        ? data.filter(filterCondition).length // Apply the filter if it exists
                        : data.length) // If no filter, consider the entire dataset
                  }
                  onChange={handleCheckAll}
                />
              </div>
            ),
            Cell: ({ row }: { row: ReactTableRow<T> }) => (
              <div>
                {!filterCondition || filterCondition(row.original) ? ( // Check if filterCondition is satisfied or not applied
                  <CheckboxCell
                    appIdx={row.original[sortByIdx]}
                    selectedIds={selectedIds || []}
                    isEnable={true}
                    onCheckboxChange={handleCheckboxChange || (() => {})}
                  />
                ) : (
                  "-"
                )}
              </div>
            ),
          },
          ...columns,
        ];
      });
    },
  );
  const { pageIndex, pageSize } = state as {
    pageIndex: number;
    pageSize: number;
  };

  const generateSortingIndicator = (column: any) => {
    if (!column.isSorted) return null;

    if (column.isSortedDesc) {
      return <i className="ri-arrow-down-s-line"></i>;
    } else {
      return <i className="ri-arrow-up-s-line"></i>;
    }
  };

  const onChangeInSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const pageSize = Number(event.target.value);
    setCurrentPage?.(0);
    setPageSize(pageSize);
    setCustomPageSize?.(pageSize);
  };

  const handlePageClick = (pageIndex: number) => {
    if (pageIndex !== currentPage) {
      setCurrentPage?.(pageIndex);
      gotoPage(pageIndex);
    }
  };

  const startPage =
    Math.floor(pageIndex / PAGE_NUMBER_LIMIT) * PAGE_NUMBER_LIMIT;
  const endPage = Math.min(startPage + PAGE_NUMBER_LIMIT, pageOptions.length);

  return (
    <Fragment>
      <Col xs={12} style={{ padding: "0 6px" }}>
        <Card>
          <CardBody>
            <Row className={classNames("d-flex", "mb-2", { "mb-3": children })}>
              <Col xs={children ? 9 : 6}>
                {children}
                {btnTitle && (
                  <Button
                    color={btnTitle === "전체대상등록" ? "primary" : "success"}
                    type="button"
                    className="waves-effect waves-light btn-sm "
                    onClick={handleAddItem}
                  >
                    {btnIcon ?? <i className="ri-add-line"></i>}
                    {btnTitle}
                  </Button>
                )}
                {btnTitle1 && (
                  <Button
                    color="success"
                    type="button"
                    className="waves-effect waves-light btn-sm ms-1"
                    onClick={handleBtn1}
                  >
                    <i className="mdi mdi-layers-triple-outline me-2"></i>
                    {btnTitle1}
                  </Button>
                )}
              </Col>
              {typeof totalRecord === "number" && totalRecord > 0 && (
                <Col xs={children ? 3 : 6}>
                  <div
                    className="d-flex justify-content-end align-items-center"
                    style={{ alignItems: "center" }}
                  >
                    <p className="mb-0 me-0 ">Total {totalRecord}</p>
                    <Col
                      md={customPageSizeOptions ? 2 : 1.5}
                      className="d-flex justify-content-end"
                    >
                      <select
                        className="form-select form-select-sm ms-2 cursor-pointer"
                        value={pageSize}
                        onChange={onChangeInSelect}
                      >
                        {PAGE_SIZE_OPTIONS.map((pageSize) => (
                          <option key={pageSize.value} value={pageSize.value}>
                            {pageSize.label}
                          </option>
                        ))}
                      </select>
                    </Col>
                  </div>
                </Col>
              )}
            </Row>

            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center ">
                <Spinner className="me-2" color="secondary" />
              </div>
            ) : data.length === 0 ? (
              <Row className="mt-5 mb-5">
                <Col className="col-12 text-center">😱</Col>
                <Col className="col-12 text-center">
                  <p>검색조건에 해당하는 데이터가 없어요!</p>
                </Col>
              </Row>
            ) : (
              <>
                <div className="table-responsive react-table ">
                  <Table
                    bordered
                    hover
                    {...getTableProps()}
                    className={className}
                  >
                    <thead className="table-light table-nowrap">
                      {headerGroups.map((headerGroup) => (
                        // eslint-disable-next-line react/jsx-key
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column: any) => (
                            <th key={column.id}>
                              <div
                                className="d-flex"
                                {...column.getSortByToggleProps()}
                              >
                                <div
                                  style={{
                                    justifyContent: "center",
                                    textAlign: "center",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  {column.id !== "checked" &&
                                    generateSortingIndicator(column)}

                                  {column.id === "checked" ? (
                                    <Input
                                      type="checkbox"
                                      className="form-check-input input-mini"
                                      checked={
                                        (selectedIds?.length || 0) > 0 &&
                                        (selectedIds?.length || 0) ===
                                          data.filter((app) => app.isFcmEnable)
                                            .length
                                      }
                                      onChange={handleCheckAll}
                                    />
                                  ) : (
                                    column.render("Header")
                                  )}
                                </div>
                              </div>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody {...getTableBodyProps()} className="text-center">
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <Fragment key={row.getRowProps().key}>
                            <tr>
                              {row.cells.map((cell) => {
                                return (
                                  // eslint-disable-next-line react/jsx-key
                                  <td
                                    {...cell.getCellProps()}
                                    className={classNames("longTextTrim", {
                                      fullText: isFullText,
                                    })}
                                  >
                                    {cell.column.id === "checked" ? (
                                      <CheckboxCell
                                        appIdx={row.original.appIdx}
                                        selectedIds={selectedIds || []}
                                        isEnable={row.original.isFcmEnable}
                                        onCheckboxChange={
                                          handleCheckboxChange || (() => {})
                                        }
                                      />
                                    ) : (
                                      cell.render("Cell")
                                    )}
                                  </td>
                                );
                              })}
                            </tr>
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
                <div className="mt-4 d-flex justify-content-center align-items-center cursor-pointer">
                  {totalPage && totalPage > 1 && (
                    <Pagination
                      aria-label="Page navigation example"
                      className="pagination-rounded"
                    >
                      <PaginationItem disabled={!canPreviousPage}>
                        <PaginationLink
                          previous
                          onClick={() => handlePageClick(pageIndex - 1)}
                        />
                      </PaginationItem>
                      {Array.from(
                        { length: endPage - startPage },
                        (_, index) => {
                          const pageIdx = startPage + index;
                          return (
                            <PaginationItem
                              key={pageIdx}
                              active={pageIdx === pageIndex}
                            >
                              <PaginationLink
                                onClick={() => handlePageClick(pageIdx)}
                              >
                                {pageIdx + 1}
                              </PaginationLink>
                            </PaginationItem>
                          );
                        },
                      )}
                      <PaginationItem disabled={!canNextPage}>
                        <PaginationLink
                          next
                          onClick={() => handlePageClick(pageIndex + 1)}
                        />
                      </PaginationItem>
                    </Pagination>
                  )}
                </div>
              </>
            )}
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default CustomTableContainerTypes;
