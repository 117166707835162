import * as Api from "@/api";
export type MenuItemStructure = {
  id: string;
  icon: string;
  label: string;
  link: string;
  subItems?: {
    id: string;
    label: string;
    link: string;
  }[];
};

export const transformMenuData = (
  menuData: Api.Response.LeftMenuItem[],
): MenuItemStructure[] => {
  const sortedMenuData = [...menuData].sort(
    (a, b) => a.dispOrder - b.dispOrder,
  );

  const parentMenus = sortedMenuData.filter((menu) => menu.pMenuIdx === 0);

  return parentMenus.map((parent) => {
    const subMenus = sortedMenuData
      .filter((menu) => menu.pMenuIdx === parent.menuIdx)
      .sort((a, b) => a.dispOrder - b.dispOrder)
      .map((subMenu) => ({
        id: subMenu.menuIdx.toString(),
        label: subMenu.name,
        link: subMenu.frontUrl || "/#",
      }));

    const menuItem: MenuItemStructure = {
      id: parent.menuIdx.toString(),
      icon: parent.icon || "",
      label: parent.name,
      link: subMenus.length > 0 ? "/#" : parent.frontUrl || "/#",
    };

    if (subMenus.length > 0) {
      menuItem.subItems = subMenus;
    }

    return menuItem;
  });
};

export const MENU_ITEMS = [
  {
    id: "dashboard",
    icon: "ri-dashboard-line",
    label: "Dashboard",
    link: "/dashboard",
  },
  {
    id: "users",
    icon: "ri-user-line",
    label: "Members",
    link: "/users",
  },
  {
    id: "material",
    icon: "ri-loader-fill",
    label: "Material Management",
    link: "/material",
  },
  {
    id: "printer",
    icon: "ri-printer-line",
    label: "Printer Management",
    link: "/printer",
  },
  {
    id: "printing-profile",
    icon: "ri-printer-cloud-line",
    label: "Printing Profile",
    link: "/printing-profile",
  },
  {
    id: "support-profile",
    icon: "ri-upload-line",
    label: "Support Profile",
    link: "/support-profile",
  },
  {
    id: "app-type",
    icon: "ri-water-flash-line",
    label: "App Type Management",
    link: "/app-type",
  },
  {
    id: "material-manufacturer",
    icon: "ri-building-line",
    label: "Material Manufacturer",
    link: "/material-manufacturer",
  },
  {
    id: "printer-manufacturer",
    icon: "ri-hotel-line",
    label: "Printer Manufacturer",
    link: "/printer-manufacturer",
  },
  {
    id: "version",
    icon: "ri-download-cloud-2-line",
    label: "Version Management",
    link: "/app-version",
  },
  {
    id: "slicing",
    icon: "ri-stack-line",
    label: "Slicing",
    link: "/#",
    subItems: [
      { id: "customer", label: "Customer Management", link: "/customer" },
      {
        id: "app-equipment",
        label: "App Management",
        link: "/app-equipment",
      },
      {
        id: "slicing-request",
        label: "Slicing Request",
        link: "/slicing-request",
      },
      {
        id: "project",
        label: "Project",
        link: "/project",
      },
      {
        id: "edit-request",
        label: "Edit Request",
        link: "/edit-request",
      },
    ],
  },
  {
    id: "statistics",
    icon: "ri-bar-chart-line",
    label: "Statistics",
    link: "/#",
    subItems: [
      {
        id: "connection-status",
        label: "Connection Status",
        link: "/connection-status",
      },
      {
        id: "registration-status",
        label: "Registration Status",
        link: "/registration-status",
      },
      {
        id: "api-request-status",
        label: "Api Request Status",
        link: "/api-request-status",
      },
      {
        id: "request-model-ranking",
        label: "Request Model Ranking",
        link: "/request-model-ranking",
      },
      {
        id: "request-region-ranking",
        label: "Request Region Ranking",
        link: "/request-region-ranking",
      },
      {
        id: "top-user-ranking",
        label: "Top User Ranking",
        link: "/top-user-ranking",
      },
      {
        id: "active-user-rate",
        label: "Active User Rate",
        link: "/active-user-rate",
      },
      {
        id: "new-subscribers",
        label: "New Subscribers",
        link: "/new-subscribers",
      },
      {
        id: "requesting-countries",
        label: "Requesting Countries",
        link: "/requesting-countries",
      },
      {
        id: "requesting-regions",
        label: "Requesting Regions",
        link: "/requesting-regions",
      },
      {
        id: "usage-by-function",
        label: "Usage By Function",
        link: "/usage-by-function",
      },
    ],
  },
  {
    id: "notice",
    icon: "ri-keyboard-line",
    label: "Notice Management",
    link: "/#",
    subItems: [
      { id: "category", label: "Category Management", link: "/category" },
      { id: "notice-board", label: "Notice Management", link: "/notice-board" },
    ],
  },
  {
    id: "operations",
    icon: "ri-magic-line",
    label: "Operations Management",
    link: "/#",
    subItems: [
      {
        id: "faq",
        label: "Faq Management",
        link: "/faq",
      },
      {
        id: "qna",
        label: "Qna Management",
        link: "/qna",
      },
      {
        id: "terms-conditions",
        label: "Terms Conditions",
        link: "/terms-conditions",
      },
    ],
  },
  {
    id: "email",
    icon: "ri-mail-send-line",
    label: "Email Management",
    link: "/#",
    subItems: [
      {
        id: "send-email",
        label: "Send Email",
        link: "/send-email",
      },
      {
        id: "emailing-history",
        label: "Email History",
        link: "/email-history",
      },
      {
        id: "email-template",
        label: "Email Template",
        link: "/email-template",
      },
    ],
  },
  {
    id: "system",
    icon: "ri-settings-4-line",
    label: "System Management",
    link: "/#",
    subItems: [
      {
        id: "account",
        label: "Account Management",
        link: "/account",
      },
      {
        id: "menu",
        label: "Menu Management",
        link: "/menu",
      },
      {
        id: "permission",
        label: "Permission Management",
        link: "/permission",
      },
      {
        id: "permission-mapping",
        label: "Permission Mapping",
        link: "/permission-mapping",
      },
    ],
  },
];
