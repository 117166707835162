import { useRef, useState } from "react";
import Flatpickr from "react-flatpickr";
import { Card, CardBody, Col, InputGroup, Label, Row } from "reactstrap";

import "./customStyles.css";
import { CustomTooltip } from "./styled";
import {
  ArrowDownIcon,
  CustomInputGroupText,
  CustomSelect,
  CustomSelectContainer,
  SearchInput,
} from "../../pages/Users/styled";

type ControlPanelProps = {
  placeholder: string;
  searchKeyword: string | (string | null)[];
  setSearchKeyword: (keyword: string) => void;
  clearFilters: () => void;
  onSearch: () => void;
  dateRange: [string, string]; // 튜플 타입으로 변경
  setDateRange: (range: [string, string]) => void; // 튜플 타입으로 변경
  select?: SelectProps[];
};

type OptionProps = {
  label: string;
  value: number;
};

type SelectProps = {
  selectTitle: string;
  options: OptionProps[];
  selectedOption: number;
  setSelectedOption: (option: number) => void;
};

const CommonControlPanel: React.FC<ControlPanelProps> = ({
  placeholder,
  searchKeyword,
  setSearchKeyword,
  clearFilters,
  onSearch,
  dateRange,
  setDateRange,
  select,
}) => {
  const flatpickrRef = useRef<Flatpickr | null>(null);
  const [isHovered, setIsHovered] = useState({
    filter: false,
    search: false,
    calendar: false,
  });

  const handleIconClick = () => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.open();
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSearch();
    }
  };

  // Date[] 를 [string, string] 로 변환하는 핸들러 추가
  const handleDateChange = (selectedDates: Date[]) => {
    const formattedDates: [string, string] = [
      selectedDates[0]
        ? `${selectedDates[0].getFullYear()}-${String(
            selectedDates[0].getMonth() + 1,
          ).padStart(2, "0")}-${String(selectedDates[0].getDate()).padStart(
            2,
            "0",
          )}`
        : "",
      selectedDates[1]
        ? `${selectedDates[1].getFullYear()}-${String(
            selectedDates[1].getMonth() + 1,
          ).padStart(2, "0")}-${String(selectedDates[1].getDate()).padStart(
            2,
            "0",
          )}`
        : "",
    ];
    setDateRange(formattedDates);
  };

  return (
    <Col xs={12} style={{ padding: "0 6px" }}>
      <Card>
        <CardBody>
          <Row>
            <Col sm={12} className="form-control-sm">
              <InputGroup>
                <SearchInput
                  value={searchKeyword}
                  type="text"
                  id="example-text-input"
                  placeholder={placeholder}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setSearchKeyword(e.target.value)
                  }
                  onKeyPress={handleKeyPress}
                />
                <CustomInputGroupText
                  onClick={clearFilters}
                  className="custom-color cursor-pointer"
                  id="FilterTooltip"
                >
                  <i className="ri-refresh-line"></i>
                </CustomInputGroupText>
                <CustomTooltip
                  placement="top"
                  isOpen={isHovered.filter}
                  target="FilterTooltip"
                  toggle={() =>
                    setIsHovered((prev) => ({ ...prev, filter: !prev.filter }))
                  }
                >
                  필터 초기화
                </CustomTooltip>
                <CustomInputGroupText
                  className="custom-color cursor-pointer"
                  onClick={onSearch}
                  id="SearchTooltip"
                >
                  <i className="ri-search-line"></i>
                </CustomInputGroupText>
                <CustomTooltip
                  placement="top"
                  isOpen={isHovered.search}
                  target="SearchTooltip"
                  toggle={() =>
                    setIsHovered((prev) => ({ ...prev, search: !prev.search }))
                  }
                >
                  검색
                </CustomTooltip>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={12} className="form-control-sm">
              <InputGroup className="cursor-pointer">
                <Flatpickr
                  ref={flatpickrRef}
                  className="form-control d-block form-control-sm"
                  placeholder="YYYY-MM-DD ~ YYYY-MM-DD"
                  options={{
                    mode: "range",
                    dateFormat: "Y-m-d",
                  }}
                  value={dateRange}
                  onChange={handleDateChange}
                />
                <CustomInputGroupText
                  className="custom-color"
                  onClick={handleIconClick}
                  id="CalendarTooltip"
                >
                  <i className="ri-calendar-event-fill cursor-pointer"></i>
                </CustomInputGroupText>
                <CustomTooltip
                  placement="top"
                  isOpen={isHovered.calendar}
                  target="CalendarTooltip"
                  toggle={() =>
                    setIsHovered((prev) => ({
                      ...prev,
                      calendar: !prev.calendar,
                    }))
                  }
                >
                  기간 선택
                </CustomTooltip>
              </InputGroup>
            </Col>
            {select &&
              select.length &&
              select.map((selectItem, index) => (
                <Col
                  sm={6}
                  className="form-select-sm"
                  key={`select-${selectItem.selectTitle}-${index}`}
                >
                  <div>
                    <Label className="form-label">
                      {selectItem.selectTitle}
                    </Label>
                    <CustomSelectContainer>
                      <CustomSelect
                        className="form-control"
                        value={selectItem.selectedOption}
                        onChange={(e) => {
                          const selectedValue = parseInt(e.target.value, 10);
                          selectItem.setSelectedOption(selectedValue);
                        }}
                      >
                        {selectItem.options.map((option, optionIndex) => (
                          <option
                            key={`option-${index}-${optionIndex}`}
                            value={option.value}
                          >
                            {option.label}
                          </option>
                        ))}
                      </CustomSelect>
                      <ArrowDownIcon className="ri-arrow-down-s-line"></ArrowDownIcon>
                    </CustomSelectContainer>
                  </div>
                </Col>
              ))}
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default CommonControlPanel;
